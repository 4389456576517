import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import Button from '@mui/material/Button';

const CustomNoRowsOverlay = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
    <span>Keine Dachflächen angelegt.</span>
  </div>
);

const RoofsDataGrid = (props: any) => {
    const columns = [
        { field: 'aspect', headerName: 'Ausrichtung nach' },
        { field: 'angle', headerName: 'Dachneigung in Grad', width: 140},
        { field: 'totalArea', headerName: 'gesamt m²', width: 80 },
        { field: 'usableArea', headerName: 'nutzbar m²', width: 80 },
		{ field: 'clouding', headerName: 'Verschattung', width: 80 }
      ]

    const [selectedRows, setSelectedRows] = useState<string[]>([]);

    const handlePurge = () => {
        const updatedRoofs = [];

        for (const roof of props.roofs) {
            if(!(selectedRows.find(element => element === roof.id.toString()))) {
                updatedRoofs.push(roof);
            }
        }
        props.setRoofs([...updatedRoofs]);
    }

    function getFriendlyNameForAspect(aspect: any) {
        if (aspect <= 25 && aspect >= -25) {
            return 'Süd';
        } else if (aspect < -25 && aspect > -66) {
            return 'Südost';
        } else if (aspect <= -66 && aspect >= -115) {
            return 'Ost';
        } else if (aspect < -115 && aspect > -150) {
            return 'Nordost';
        } else if (aspect < 150 && aspect > 115) {
            return 'Nordwest';
        } else if (aspect <= 115 && aspect >= 66) {
            return 'West';
        } else if (aspect < 66 && aspect > 25) {
            return 'Südwest';
        } else if (Math.abs(aspect) >= 150 &&  Math.abs(aspect) <= 180) {
            return 'Nord';
        } else {
            return 'Unbekannt';
        }
    }

	function getClouding (clouding: any) {
		if (clouding == "none") {
			return 'keine';
		} else if (clouding == "low") {
			return 'leicht'
		} else if (clouding == "medium") {
			return 'mittel'
		} else if (clouding == "high") {
			return 'hoch'
		}
	}

    const getTableRows = (): any => {
        if (props.roofs) {
            const tableRows: any[] = [];
            let i = 0;
            for(const roof of props.roofs) {			
                tableRows[i] = {};
                tableRows[i]['id'] = roof.id;
                tableRows[i]["aspect"] = roof.aspect + "° (" + getFriendlyNameForAspect(roof.aspect) + ")";
                tableRows[i]["angle"] = roof["angle"] + "°";
                tableRows[i]["totalArea"] = roof["totalArea"] + "m²";
                tableRows[i]["usableArea"] = roof["usableArea"] + "m²";
				tableRows[i]["clouding"] = getClouding (roof["clouding"]);
                i += 1;
            }
            return tableRows;
        } else {
            return [];
        }
    }
  
    return (
        <React.Fragment>
        <div style={{ height: 400, width: '100%' }}>
        <DataGrid sx={{ mt: 3, mb: 3 }}
        rows={getTableRows()}
        columns={columns}
        checkboxSelection
        onRowSelectionModelChange={(ids) => {
            const _selectedRows : string[] = [];
            ids.forEach(e => (_selectedRows.push(e.toString())))
            setSelectedRows(_selectedRows);
          }}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        />
        </div>
        <Button variant="contained" color="primary" onClick={handlePurge} sx={{ mt: 3 }}>
          Ausgewählte Dachflächen löschen
        </Button>
        </React.Fragment>
    )
}
  
export default RoofsDataGrid
