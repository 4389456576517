import React, { useState, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import Grid from '@mui/material/Grid';
import { FormControlLabel, Checkbox, TextField, Typography } from '@mui/material';


const ProjectAddressSection = forwardRef(( props: any, _ref:any ) => {
  const [street, setStreet] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('');
  const [differentProjectAddress, setDifferentProjectAddress] = useState(false);

  useEffect(() => {
    if(props.applicationState.customer.kind === 'existing') {
      if(props.applicationState.selectedDeal.projectAddress.deviates === true) {
        setDifferentProjectAddress(true);
        if(props.applicationState.selectedDeal.projectAddress.street) {
          setStreet(props.applicationState.selectedDeal.projectAddress.street);
        }
        if (props.applicationState.selectedDeal.projectAddress.streetNumber) {
          setStreetNumber(props.applicationState.selectedDeal.projectAddress.streetNumber);
        }
        if (props.applicationState.selectedDeal.projectAddress.city) {
          setCity(props.applicationState.selectedDeal.projectAddress.city);
        }
        if (props.applicationState.selectedDeal.projectAddress.zip) {
          setZip(props.applicationState.selectedDeal.projectAddress.zip);
        }
        if (props.applicationState.selectedDeal.projectAddress.country) {
          setCountry(props.applicationState.selectedDeal.projectAddress.country);
        }
      } 
    }
  }, []);

  useImperativeHandle(_ref, () => ({
    getChildCount: () => {
      return {
        street,
        streetNumber,
        zip,
        city,
        country,
        deviates: differentProjectAddress
      };
    },
  }));

  const handleDifferentProjectAddressCheckboxChange = (checked:any) => {
    setDifferentProjectAddress(checked);
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        Adresse des Bauprojekts
	    <FormControlLabel
	      control={<Checkbox id="differentProjectAddress" checked={differentProjectAddress} onChange={e => handleDifferentProjectAddressCheckboxChange(e.target.checked)} />}
	      label="Projektadresse weicht von Anschrift ab"
	      sx={{ml: 2}}
	    />
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <TextField
            id="projectAddress1"
            name="projectAddress1"
            label="Straße des Bauprojekts"
            disabled={!differentProjectAddress}
            value={street}
            onChange={e => setStreet(e.target.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id="projectStreetAddress1"
            name="projectStreetAddress1"
            label="Hausnummer des Bauprojekts"
            disabled={!differentProjectAddress}
            value={streetNumber}
            onChange={e => setStreetNumber(e.target.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="projectZip"
            name="projectZip"
            label="Postleitzahl"
            disabled={!differentProjectAddress}
            value={zip}
            onChange={e => setZip(e.target.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="projectCity"
            name="projectCity"
            label="Stadt"
            disabled={!differentProjectAddress}
            value={city}
            onChange={e => setCity(e.target.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="projectCountry"
            name="projectCountry"
            label="Land"
            disabled={!differentProjectAddress}
            value={country}
            onChange={e => setCountry(e.target.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default React.memo(ProjectAddressSection);
