import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { ThemeProvider } from '@mui/material/styles';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import SignInPage from './components/SignInPage'
import WebformSurvey from './components/WebformSurvey';

import autarqTheme from './helper/AutarqTheme'

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.autarq.com">
        Autarq GmbH
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}

export default function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={autarqTheme}>
        <AuthenticatedTemplate>
          <WebformSurvey />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <SignInPage />
        </UnauthenticatedTemplate>
      </ThemeProvider>
    </React.Fragment>
  );
}