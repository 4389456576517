import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import { Select, InputLabel, MenuItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';

export default function NewRoofCard(this: any, props: any) {
  const [addButtonDisabled, setAddButtonDisabled] = React.useState(true);

  const [aspect, setAspect] = React.useState();
  const [angle, setAngle] = React.useState();
  const [totalArea, setTotalArea] = React.useState();
  const [usableArea, setUsableArea] = React.useState();
  const [clouding, setClouding] = React.useState("none");
  
  const checkForSufficientInformationsProvided = (checkForFields: any[]):void => {
    var checksPassed = false;
    var index = 0;
    for (var field of checkForFields) {
      console.log("Check for field: " + field);
      if (field == null || field == '') {
        checksPassed = false;
        break;
      } else if (index === 0 && (parseInt(field) < -180 || parseInt(field) > 180) || isNaN(field as any)) {
        checksPassed = false;
        break;
      } else if (index === 1 && (parseInt(field) < 0 || parseInt(field) > 90) || isNaN(field as any)) {
        checksPassed = false;
        break;
      } else if (index === 2 && (parseInt(field) <= 0) || isNaN(field as any)) {
        checksPassed = false;
        break;
      } else if (index === 3 && (parseInt(field) <= 0) || isNaN(field as any)) {
        checksPassed = false;
        break;
      } else {
        checksPassed = true;
      }
      index += 1;
    }
    if (checksPassed) {
      setAddButtonDisabled(false);
    } else {
      setAddButtonDisabled(true);
    }
  };

  const updateAspect = (textFieldValue: any): void => {
    checkForSufficientInformationsProvided([textFieldValue.target.value, angle, totalArea, usableArea]);
    setAspect(textFieldValue.target.value);
  };

  const updateAngle = (textFieldValue: any): void => {
    checkForSufficientInformationsProvided([aspect, textFieldValue.target.value, totalArea, usableArea]);
    setAngle(textFieldValue.target.value);
  };

  const updateTotalArea = (textFieldValue: any): void => {
    checkForSufficientInformationsProvided([aspect, angle, textFieldValue.target.value, usableArea]);
    setTotalArea(textFieldValue.target.value);
  };

  const updateUsableArea = (textFieldValue: any): void => {
    checkForSufficientInformationsProvided([aspect, angle, totalArea, textFieldValue.target.value]);
    setUsableArea(textFieldValue.target.value);
  };

  const updateHandleCloudingChange = (e: any): void => {
	setClouding(e.target.value);
};

  const updateTechnicalUserInput = (): void => {
    let id = 0;
    if(props.roofs.length > 0) {
      id = Math.max.apply(Math, props.roofs.map((o: { id: any; }) => o.id)) + 1;
    }
    props.setRoofs([...props.roofs, {id, aspect, angle, totalArea, usableArea, clouding}]);
  };

  const InfoIcon = () => (
	  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
		  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
		  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
	  </svg>
  );

  const InfoAusrichtungNach = 'Die Ausrichtung gibt an, in welcher Himmelsrichtung das Dach Steht. Dabei gehen die Werte von -180 bis 180 Grad. Dabei ist 180 Norden und 0 Süden.';
  const InfoDachneigung = 'Die Dachneigung muss einen Wert zwischen 0 und 90 Grad haben.'

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 18, marginBottom: 1 }} color="text.secondary" gutterBottom>
          Dachfläche hinzufügen
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
	    <TextField id="outlined-basic" InputProps={{
            endAdornment: <InputAdornment position="end">°</InputAdornment>,
            }} label='Ausrichtung' variant="outlined" onChange={updateAspect} required/>
      	    <Tooltip title={InfoAusrichtungNach} arrow tabIndex={-1}>
		<IconButton aria-label="info">
			<InfoIcon />
	        </IconButton>
	  </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField id="outlined-basic" InputProps={{
            endAdornment: <InputAdornment position="end">°</InputAdornment>,
            }} label='Dachneigung' variant="outlined" onChange={updateAngle} required/>
      	    <Tooltip title={InfoDachneigung} arrow tabIndex={-1}>
		<IconButton aria-label="info">
			<InfoIcon />
	        </IconButton>
	  </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField id="outlined-basic" InputProps={{
            endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }} label='Gesamtfläche' variant="outlined" onChange={updateTotalArea} required/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField id="outlined-basic" InputProps={{
            endAdornment: <InputAdornment position="end">m²</InputAdornment>,
            }} label='Nutzbare Fläche' variant="outlined" onChange={updateUsableArea} required/>
          </Grid>
		  <Grid item xs={12} sm={6}>
		  <FormControl sx={{ my: 2 }} fullWidth>
					<InputLabel id="clouding-select-label">Verschattung</InputLabel>
					<Select
						labelId="clouding-label"
						id="clouding-select"
						label="Verschattung"
						onChange={updateHandleCloudingChange}
					>
						<MenuItem value={"none"}>keine</MenuItem>
						<MenuItem value={"low"}>leicht</MenuItem>
						<MenuItem value={"medium"}>mittel</MenuItem>
						<MenuItem value={"high"}>hoch</MenuItem>
					</Select>
				</FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Tooltip title="Add" >
          <span>
            <Button variant="contained" color="primary" disabled={addButtonDisabled} onClick={updateTechnicalUserInput} sx={{ mt: 3 }}>
              Dachfläche hinzufügen
            </Button>
          </span>
        </Tooltip>
      </CardActions>
    </Card>
  );
}
