import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useMsal } from "@azure/msal-react";
import { queryMiddleware } from "../../helper/queryMiddleware";
import { loginRequest } from "../../authConfig";

let middlewareUrl = 'http://localhost:3001';
if (process.env.NODE_ENV === 'production') {
  // dev / staging is on 3000
  middlewareUrl = 'https://webform-api.autarq.net';
} else if (process.env.NODE_ENV === 'test') {
  middlewareUrl = 'https://webform-api.autarq.net';
}

const DealDataGrid = (props: any) => {
  const columns = [
    { field: '69241763d34bb9a77611b3d109d16fe4ef76b16f', headerName: 'Vorname', width: 150 },
    { field: '56f876a7447c4c6ddb88c08aa1edaa7507002c70', headerName: 'Nachname', width: 190 },
    { field: "279f244bc10becfe935f1db8970a109c49bf9cdc", headerName: 'Straße', width: 160 },
    { field: "5d496f9741b2fd4154fd61db66da95b339bd6edd", headerName: 'Hausnummer', width: 110 },
    { field: "1c533384908157aa5efca3abc5b434cc75c32390", headerName: 'Postleitzahl', width: 70 },
    { field: "456fc648964c054c2fb67cd8109bfcd5dcc7a55e", headerName: 'Stadt', width: 100 }
  ]

  const {instance, accounts} = useMsal();

  const [tableData, setTableData] = useState([]);
  const [rowStateMessage, setRowStateMessage] = useState('Lädt ...');

  const CustomNoRowsOverlay = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
      <span>{rowStateMessage}</span>
    </div>
  );

  useEffect(() => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    const url   = `${middlewareUrl}/api/v1/get-deals`;

    instance.acquireTokenSilent(request).then((response) => {
      queryMiddleware(url, {}, "POST", response.accessToken)
      .then((data) => processGetDealsAnswer(data))
    }).catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        queryMiddleware(url, {}, "POST", response.accessToken)
          .then((data) => processGetDealsAnswer(data))
      });
    });
  }, []);

  const processGetDealsAnswer = (data:any) => {
    console.log(data.success);
    if(data.hasOwnProperty('success')) {
      console.log("data has success-field");
      if(data.success === false) {
        if(data.hasOwnProperty('errorCode')) {
          setRowStateMessage(`Error: '${data.details}' with errorCode: ${data.errorCode}`);
          return;
        } else {
          setRowStateMessage(`Error: Generic error!`);
          return;
        }
      }
      if(data.success === true) {
        setTableData(data.data);
        return;
      }
    }
    setRowStateMessage(`Error: Unknown error in answer from server!`);
  }

  const getParticipantsForDeal = (selectedDeal: any) => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    const url   = `${middlewareUrl}/api/v1/get-participants`;
    const body  = {
      dealId: selectedDeal.id,
    };

    instance.acquireTokenSilent(request).then((response) => {
      queryMiddleware(url, body, "POST", response.accessToken)
        .then((data) => {
          selectedDeal.participants = data.data;
          props.handleSelection(selectedDeal);
        })
    }).catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        queryMiddleware(url, body, "POST", response.accessToken)
        .then((data) => {
          selectedDeal.participants = data.data;
          props.handleSelection(selectedDeal);
        })
      });
    }); 
  };

  const processRoofData = (Deal:any, selectedDeal: any) => {

    selectedDeal.project.roofs = [];
    // roof 1
    if(
      Deal['bce5d774fca45fd7e6d67b61040f1380569b5fda'] !== null &&
      Deal['a9ab0b61cb6c64f1250578b9b2913437f0c20cd0'] !== null &&
      Deal['0ae72d32815126c158cf2cbaf95a61335934f4ae'] !== null &&
      Deal['98b7d545c8206c664d4480f84c80b46b34a584c8'] !== null)
    {
      selectedDeal.project.roofs.push({
        id: 0,
        aspect: Deal['bce5d774fca45fd7e6d67b61040f1380569b5fda'],
        angle: Deal['a9ab0b61cb6c64f1250578b9b2913437f0c20cd0'],
        totalArea: Deal['98b7d545c8206c664d4480f84c80b46b34a584c8'],
		usableArea: Deal['0ae72d32815126c158cf2cbaf95a61335934f4ae'],
		clouding: Deal['b3a1cf7707c4699901fa92d27fb16a1dda1d56bb'] || 'none'
      })
    }
    // roof 2
    if(
      Deal['5ef7de6f7268a9b011b2f239726c665be53af33f'] !== null &&
      Deal['8831f0759fe1a526b1df6554acf4719745add0db'] !== null &&
      Deal['84f0648b9c25123a54ce66689f4950733718ffe8'] !== null &&
      Deal['c660c0536c48c6f49ccc404570d6d7654abe3564'] !== null)
    {
      selectedDeal.project.roofs.push({
        id: 1,
        aspect: Deal['5ef7de6f7268a9b011b2f239726c665be53af33f'],
        angle: Deal['8831f0759fe1a526b1df6554acf4719745add0db'],
        totalArea: Deal['c660c0536c48c6f49ccc404570d6d7654abe3564'],
		usableArea: Deal['84f0648b9c25123a54ce66689f4950733718ffe8'],
		clouding: Deal['0b933a4aad5a71cd55553af521b179896f3d3f12'] || 'none'
      })
    }
    // roof 3
    if(
      Deal['20c6aaf5d46909aabd6b087c445b63753bd87a26'] !== null &&
      Deal['eeab3f320233d3e06dc69e62e4ce9cec69ca8b2e'] !== null &&
      Deal['5a736f9a4b8a3ef21499b2d3c451b3dce9408073'] !== null &&
      Deal['a91f53c41a77839ea3817e6cfa65fb6f27eb6ca5'] !== null)
    {
      selectedDeal.project.roofs.push({
        id: 2,
        aspect: Deal['20c6aaf5d46909aabd6b087c445b63753bd87a26'],
        angle: Deal['eeab3f320233d3e06dc69e62e4ce9cec69ca8b2e'],
        totalArea: Deal['a91f53c41a77839ea3817e6cfa65fb6f27eb6ca5'],
        usableArea: Deal['5a736f9a4b8a3ef21499b2d3c451b3dce9408073'],
		clouding: Deal['76b896ca9082ceb1e50bac4ed984086cd19fdbc7'] || 'none'
      })
    }
    // roof 4
    if(
      Deal['4253c0ddcf3c5f674d4a2d5907db89564f725bda'] !== null &&
      Deal['b1bce9ca1d9d99dbe7fcb2a1a8938a61a2291b3f'] !== null &&
      Deal['69a8d933c668740a9fe72a1ec683894f867a6cfb'] !== null &&
      Deal['ee97759e2a34fc105e1f14c16982eb21542716e5'] !== null)
    {
      selectedDeal.project.roofs.push({
        id: 3,
        aspect: Deal['4253c0ddcf3c5f674d4a2d5907db89564f725bda'],
        angle: Deal['b1bce9ca1d9d99dbe7fcb2a1a8938a61a2291b3f'],
        totalArea: Deal['ee97759e2a34fc105e1f14c16982eb21542716e5'],
        usableArea: Deal['69a8d933c668740a9fe72a1ec683894f867a6cfb'],
		clouding: Deal['2883136a9de6fa87be76ecec106eb7a152431b26'] || 'none'
      })
    }
    // roof 5
    if(
      Deal['fff358d1097a5e3cc2fbc580acf5c09ed62441a6'] !== null &&
      Deal['c19a54a7d7f8c6c335261050dd5baadde27828de'] !== null &&
      Deal['795b477ef866862bc76e9e3e601e351d8569c957'] !== null &&
      Deal['55bd00a6a02e7c7d1c96521d88a35795d0eb51cb'] !== null)
    {
      selectedDeal.project.roofs.push({
        id: 4,
        aspect: Deal['fff358d1097a5e3cc2fbc580acf5c09ed62441a6'],
        angle: Deal['c19a54a7d7f8c6c335261050dd5baadde27828de'],
        totalArea: Deal['55bd00a6a02e7c7d1c96521d88a35795d0eb51cb'],
        usableArea: Deal['795b477ef866862bc76e9e3e601e351d8569c957'],
		clouding: Deal['3e16b8eadd62dc80650784ae4e53d3666ca91bb7'] || 'none'
      })
    }
  };

  const processRoofAndHouseType = (Deal: any, selectedDeal: any) => {
    if( Deal['55a3d76075ba4351f44e067da0a4fbbc6e6ec623'] ) {
      if ( Deal['55a3d76075ba4351f44e067da0a4fbbc6e6ec623'] === '215' ) {
        selectedDeal.project.allocation = 'full';
        delete selectedDeal.project.maximumTileCount;
      } else if ( Deal['55a3d76075ba4351f44e067da0a4fbbc6e6ec623'] === '216' ) {
        selectedDeal.project.allocation = 'semi';
        if(Deal['75449314057862ed95090e7bd0fca8f4492ef6c3']) {
          selectedDeal.project.maximumTileCount = Deal['75449314057862ed95090e7bd0fca8f4492ef6c3'];
        }
      } else {
        delete selectedDeal.project.fullyAssigned;
        delete selectedDeal.project.maximumTileCount;
      }
    }
    if( Deal['556ebf20b84a0e055035ce2d23bb741354d7413b'] ) {
      if ( Deal['556ebf20b84a0e055035ce2d23bb741354d7413b'] === '221' ) {
        selectedDeal.project.roofCondition = 'in-planning';
      } else if ( Deal['556ebf20b84a0e055035ce2d23bb741354d7413b'] === '222' ) {
        selectedDeal.project.roofCondition = 'to-renovate';
      } else if ( Deal['556ebf20b84a0e055035ce2d23bb741354d7413b'] === '223' ) {
        selectedDeal.project.roofCondition = 'isListed';  
      } else {
        delete selectedDeal.project.roofCondition;
      }
    }
    if( Deal['3d8e5bedfb18af5448a8fe53c537a64392cfd489'] ) {
      if ( Deal['3d8e5bedfb18af5448a8fe53c537a64392cfd489'] === '217' ) {
        selectedDeal.project.houseType = 'detached';
      } else if ( Deal['3d8e5bedfb18af5448a8fe53c537a64392cfd489'] === '218' ) {
        selectedDeal.project.houseType = 'semi-detached';
      } else if ( Deal['3d8e5bedfb18af5448a8fe53c537a64392cfd489'] === '219' ) {
        selectedDeal.project.houseType = 'multi-family';
      } else if ( Deal['3d8e5bedfb18af5448a8fe53c537a64392cfd489'] === '220' ) {
        selectedDeal.project.houseType = 'townhouse';
      } else if ( Deal['3d8e5bedfb18af5448a8fe53c537a64392cfd489'] === '228' ) {
        selectedDeal.project.houseType = 'non-residential';
      } else {
        delete selectedDeal.project.houseType;
      }
    }

    if( Deal['0281e695a8477133f04ec6ea2324e463c2bca0db'] ) {
      if ( Deal['0281e695a8477133f04ec6ea2324e463c2bca0db'] === null ) {
        delete selectedDeal.project.numberOfStories;
      }
      selectedDeal.project.numberOfStories = Deal['0281e695a8477133f04ec6ea2324e463c2bca0db'];
    }
  }

  const processLoftAndPlantRoom = (Deal: any, selectedDeal: any) => {
    if(Deal['9bffb303a673ae76e11c6c75f8609ba9f60a7527']) {
      if (Deal['9bffb303a673ae76e11c6c75f8609ba9f60a7527'] === '213') {
        selectedDeal.project.loftIsDeveloped = true;
      } else if(Deal['9bffb303a673ae76e11c6c75f8609ba9f60a7527'] === '214') {
        selectedDeal.project.loftIsDeveloped = false;
      } else {
        delete selectedDeal.project.loftIsDeveloped;
      }
    }
    if(Deal['850b0a536358b7576b201261f19499ee2cb92d79']) {
      if (Deal['850b0a536358b7576b201261f19499ee2cb92d79'] === '211') {
        selectedDeal.project.loftHasSpace = true;
      } else if(Deal['850b0a536358b7576b201261f19499ee2cb92d79'] === '212') {
        selectedDeal.project.loftHasSpace = false;
      } else {
        delete selectedDeal.project.loftHasSpace;
      }
    }
    if(Deal['4c1d99612e57703f52484bedb016dba71db2f37b']) {
      if (Deal['4c1d99612e57703f52484bedb016dba71db2f37b'] === '209') {
        selectedDeal.project.plantRoomHasSpace = true;
      } else if(Deal['4c1d99612e57703f52484bedb016dba71db2f37b'] === '210') {
        selectedDeal.project.plantRoomHasSpace = false;
      } else {
        delete selectedDeal.project.plantRoomHasSpace;
      }
    }
  }

  const processDrawings = (Deal: any, selectedDeal: any) => {
    if(Deal['0f6ebdedd9af7f10c0f8d19e9444f76c10248092']) {
      if (Deal['0f6ebdedd9af7f10c0f8d19e9444f76c10248092'] === '207') {
        selectedDeal.project.drawingExisting = true;
      } else if (Deal['0f6ebdedd9af7f10c0f8d19e9444f76c10248092'] === '208') {
        selectedDeal.project.drawingExisting = false;
      } else {
        delete selectedDeal.project.drawingExisting;
      }
    }
    
    if(Deal['8ad04a5ab792223daad3177c6df023f15691466b']) {
      if (Deal['8ad04a5ab792223daad3177c6df023f15691466b'] === '205') {
        selectedDeal.project.measurementExisting = true;
        if(Deal['71dc7dfe05a7b36195c5b4675188bd86ce3601a5']) {
          if (Deal['71dc7dfe05a7b36195c5b4675188bd86ce3601a5'] === '203') {
            selectedDeal.project.roofEntryPointMarked = true;
          } else if (Deal['71dc7dfe05a7b36195c5b4675188bd86ce3601a5'] === '204') {
            selectedDeal.project.roofEntryPointMarked = false;
          }
        }
        if(Deal['9619d2ad824a3d7f3428fa19ad510566485bab53']) {
          if (Deal['9619d2ad824a3d7f3428fa19ad510566485bab53'] === '201') {
            selectedDeal.project.locationPVWMarked = true;
          } else if (Deal['9619d2ad824a3d7f3428fa19ad510566485bab53'] === '202') {
            selectedDeal.project.locationPVWMarked = false;
          }
        }
      } else if (Deal['8ad04a5ab792223daad3177c6df023f15691466b'] === '206') {
        selectedDeal.project.measurementExisting = false;
        delete selectedDeal.project.roofEntryPointMarked;
        delete selectedDeal.project.locationPVWMarked;
      } else {
        delete selectedDeal.project.measurementExisting;
        delete selectedDeal.project.roofEntryPointMarked;
        delete selectedDeal.project.locationPVWMarked;
      }
    }
  }

  const processProjectAddressDeviation = (Deal: any, selectedDeal: any) => {
    if(!(
      selectedDeal.person.street === Deal["279f244bc10becfe935f1db8970a109c49bf9cdc"] &&
      selectedDeal.person.streetNumber === Deal["5d496f9741b2fd4154fd61db66da95b339bd6edd"] &&
      selectedDeal.person.zip === Deal["1c533384908157aa5efca3abc5b434cc75c32390"] &&
      selectedDeal.person.city === Deal["456fc648964c054c2fb67cd8109bfcd5dcc7a55e"] &&
      selectedDeal.person.country === Deal["e6cb070619c24efda514c7f70f545c3799a4de8e"]
    )) {
      selectedDeal.projectAddress.deviates = true;
      selectedDeal.projectAddress.street = Deal["279f244bc10becfe935f1db8970a109c49bf9cdc"];
      selectedDeal.projectAddress.streetNumber = Deal["5d496f9741b2fd4154fd61db66da95b339bd6edd"];
      selectedDeal.projectAddress.zip = Deal["1c533384908157aa5efca3abc5b434cc75c32390"];
      selectedDeal.projectAddress.city = Deal["456fc648964c054c2fb67cd8109bfcd5dcc7a55e"];
      selectedDeal.projectAddress.country = Deal["e6cb070619c24efda514c7f70f545c3799a4de8e"];
    }
  }

  const handleOnClick = (rowData: any): void => {
    for (const Deal of tableData) {
        if(rowData.id.toString() == Deal["id"]) {
            const selectedDeal = {
              id: Deal['id'],
              person: {
                firstName: Deal['69241763d34bb9a77611b3d109d16fe4ef76b16f'],
                lastName: Deal['56f876a7447c4c6ddb88c08aa1edaa7507002c70'],
                street: Deal['5c4c645f703dacd6ba0550639dbee2a1ea737704'],
                streetNumber: Deal['20356570efcc06c36a5732a43fb411b8967c606e'],
                city: Deal['3c2fb055a3d58981555b12312379ec56f269b775'],
                zip: Deal['39b4b3f4b3a797384209c64de7eebcc8b354dbe0'],
                country: Deal['89f2fc761da250567924fce215dcfda7ec6b3212'],
              },
              general: {
                desiredInstallationDate: Deal['1be7ee6f8683ea803abb7a26cabbf65cbec6adcc'],
                consumptionPeople: Deal['8002e84d4d4f6754f489a546e4120f579469aeda'],
                annualPowerDemand: Deal['fddb53881a13870fc421edb9d38afdd4c937bbc4'],
                estimatedBudget: Deal['1d7263c2b026a6babb2e49ce543ac53b4e4a62d1'],
              },
              projectAddress: {
                deviates: false,
              },
              project: {
                roofCondition: Deal['556ebf20b84a0e055035ce2d23bb741354d7413b'],
                houseType: Deal['3d8e5bedfb18af5448a8fe53c537a64392cfd489'],
                clouding: Deal['ca5a4e28944beaa727a43aaf252ad5edf8b0d653'],
                tile: Deal['fcba96eb3fef7cb89e96e52ec2222e1bdfae919c'],
              },
              roofs: [],
            };
            processProjectAddressDeviation(Deal, selectedDeal);
            processRoofData(Deal, selectedDeal);
            processRoofAndHouseType(Deal, selectedDeal);
            processLoftAndPlantRoom(Deal, selectedDeal);
            processDrawings(Deal, selectedDeal);
            getParticipantsForDeal(selectedDeal);
            break;
        }
    }
       
  }

  return (
    <div style={{ height: 740, width: '100%' }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        onRowClick={(rowData) => handleOnClick(rowData)}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
      />
    </div>
  )
}

export default DealDataGrid
