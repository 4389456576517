import { createTheme } from '@mui/material/styles';

const autarqTheme = createTheme({
    palette: {
      primary: {
        main: '#fff365',
      },
      secondary: {
        main: '#11cb5f',
      },
    },
  });

export default autarqTheme;