import React, { useState, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

import AddPersonModal from './AddPersonModal';

interface FunctionPersonData {
    key: number;
    pipedriveId?: number,
    label: string;
	contactTypes: string,
    company?: string;
    firstName: string;
    lastName: string;
    street: string;
    streetNumber: string;
    zip: string;
    city: string;
    country?: string;
    email: string;
    deleted: boolean;
};

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const AddPersonPane = forwardRef(( props: any, _ref:any ) => {
    const functionPersonStateRef = useRef();
    const [personKey, setPersonKey] = React.useState(0);
    const [functionPersons, setFunctionPersons] = React.useState<FunctionPersonData[]>([]);

    useEffect(() => {
        if(props.applicationState.customer.kind === 'existing') {
          if(!props.applicationState.selectedDeal.participants) {
            return;
          }
          if(!Array.isArray(props.applicationState.selectedDeal.participants)) {
            return;
          }
  
          let i = 0;
          let chips:any[] = [];
          for (const participant of props.applicationState.selectedDeal.participants) {
			let cct = ''
			try {
				cct = props.applicationState.contactTypes.find((item: any) => item.id === parseInt (participant.person['b51d6e812905a59b23039fb34e654b9dc1659a66'])).label
			}
			catch {
				cct = '';
			}
            const functionPerson:FunctionPersonData = {
              key: i,
              pipedriveId: participant.id,
			  contactTypes: cct,
              label: `${participant.person.first_name} ${participant.person.last_name}`,
              firstName: participant.person.first_name,
              lastName: participant.person.last_name,
              street: participant.person['71310f172eea39b933c9eec0853a98fdcb2b7ba2'],
              streetNumber: participant.person['71310f172eea39b933c9eec0853a98fdcb2b7ba2'],
              zip: participant.person['1a56c3fe83a3c4d6289c41a34576f2a467dead3e'],
              city: participant.person['88b5693597ba57dabb69048fb990ae156b6408c4'],
              email: participant.person.email,
              deleted: false,
            };		
            chips.push(functionPerson);
            i += 1;
          }
          setPersonKey(i + 1);
          setFunctionPersons([...chips]);
        }
      }, []);

    useImperativeHandle(_ref, () => ({
        getChildData: () => {
          return functionPersons;
        },
    }));

    const handleModalClose = () => {
        const ref = functionPersonStateRef.current as any;
        if(ref === undefined) {
          return '';
        }

        const childData = ref.getChildData();
		if (childData.lastName) {		
			setFunctionPersons((chips) => {	
				const functionPerson:FunctionPersonData = {
					key: personKey,
					label: `${childData.firstName} ${childData.lastName}`,
					contactTypes: childData.contactTypes,
					firstName: childData.firstName,
					lastName: childData.lastName,
					street: childData.street,
					streetNumber: childData.streetNumber,
					zip: childData.zip,
					city: childData.city,
					email: childData.email,
					deleted: false,
				};
				if (childData.company !== undefined) {
					functionPerson.company = childData.company;
				}
				if (childData.country !== undefined) {
					functionPerson.country = childData.country;
				}				
				chips.push(functionPerson);				
				setPersonKey(personKey + 1);
				return [...chips];
			});
		}
    };

    const handleDelete = (chipToDelete: FunctionPersonData) => () => {
        // setFunctionPersons((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
        for(const functionPerson of functionPersons) {
          if (functionPerson.key === chipToDelete.key) {
            functionPerson.deleted = true;
          }
        }
        setFunctionPersons([...functionPersons]);
    };

    return (
        <React.Fragment>		    
            <AddPersonModal ref={functionPersonStateRef} handleModalClose={ handleModalClose } applicationState={props.applicationState}></AddPersonModal>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'left',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    p: 0.5,
                    m: 0,
                    mt: 2,
                }}
                component="ul"
                >
                {functionPersons.filter(data => data.deleted !== true).map((data) => {
                    let icon;

                    return (
                    <ListItem key={data.key}>
                        <Chip
                        icon={icon}
                        label={data.label+ ' (' + data.contactTypes + ')'}
                        onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                        />
                    </ListItem>
                    );
                })}
                </Box>
        </React.Fragment>
    );
});

export default React.memo(AddPersonPane);

