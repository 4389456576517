import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import { useMsal } from "@azure/msal-react";
import { queryMiddleware } from "../../helper/queryMiddleware";
import { loginRequest } from "../../authConfig";

export default function StepButtons(props: any) {
  const [query, setQuery] = React.useState('idle');
  const [response, setResponse] = React.useState('');
  const [notificationAvailable, setNotificationAvailable] = React.useState(false);
  const [severity, setSeverity] = React.useState<any>('');

  const {instance, accounts} = useMsal();

  const handleQueryResponse = (response:any) => {
    console.log(response);
    if(response.success === true) {
      setResponse('Eintrag erfolgreich')
      setSeverity('success')
      props.setActiveStep(props.activeStep + 1);
    } else {
      setResponse('Eintrag nicht erfolgreich')
      setSeverity('error')
      setNotificationAvailable(true);
    }
    setQuery('finished');
  };

  const handleClick = () => {
    if(props.applicationState.customer.kind === 'new' && props.activeStep === 0) {
      props.setActiveStep(props.activeStep + 2);
    } else if (props.activeStep !== 2) {
      props.setActiveStep(props.activeStep + 1);
    }
    if(props.activeStep === 2) {
      const ref = props.dataInputRef.current as any;
      if(ref === undefined) {
        return '';
      }
      const childState = ref.getChildCount();
      sendQuery(childState);
    }
    if (props.activeStep === 3) {
	    props.setActiveStep(0);
    }
  };

  const handleBack = () => {
    if(props.applicationState.customer.kind === 'new' && props.activeStep === 2) {
      props.setActiveStep(props.activeStep - 2);
    } else {
      props.setActiveStep(props.activeStep - 1);
    }
  };

  

  const sendQuery = (data: any) => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    setQuery('progress');
    setNotificationAvailable(false);

    let method = '';
    if(props.applicationState.customer.kind === 'existing') {
      method = 'PUT';
    } else if (props.applicationState.customer.kind === 'new') {
      method = 'POST';
    } else {
      console.log('illegal state, aborting');
      return;
    }
    let middlewareUrl = 'http://localhost:3001';
    if (process.env.NODE_ENV === 'production') {
      // dev / staging is on 3000
      middlewareUrl = 'https://webform-api.autarq.net';
    } else if (process.env.NODE_ENV === 'test') {
      middlewareUrl = 'https://webform-api.autarq.net';
    }
    
    const url   = `${middlewareUrl}/api/v1/add-deal`;
    const body  = {
      dealId: data.id,
      person: data.person,
      general: data.general,
      projectAddress: data.projectAddress,
      projectDetails: data.projectDetails,
      functionPersons: data.functionPersons,
    };
console.log (body);
    instance.acquireTokenSilent(request).then((response) => {
      queryMiddleware(url, body, method, response.accessToken)
        .then((response) => handleQueryResponse(response));
      }).catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
        queryMiddleware(url, body, method, response.accessToken)
          .then((response) => handleQueryResponse(response));
      });
    });
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {
        props.activeStep !== 0 && (
          <Button onClick={handleBack} sx={{ mt: 3, ml: 1, display: props.activeStep === props.steps.length - 1 ? "none" : "block" }}>
            Zurück
          </Button>
          )
        }
        <Button variant="contained" disabled={ (props.requiredFieldsProvided === false && (props.activeStep === 1 || props.activeStep === 2)) ? true : false } onClick={handleClick} sx={{ mt: 3, mx: 1}}>
		{props.activeStep === props.steps.length - 2 ? 'Eintragen' : props.activeStep === props.steps.length - 1 ? 'Fertig' : 'Weiter'}
        </Button>
      </Box>
      <Box sx={{ height: 40, mt: 3, mx: 1 }}>
        {
          <Fade
            in={query === 'progress'}
            style={{
              transitionDelay: query === 'progress' ? '200ms' : '0ms',
            }}
            unmountOnExit
          >
            <CircularProgress size="1.5rem"/>
          </Fade>
        }
      </Box>
      <Collapse in={notificationAvailable} sx={{ my: 2, mx: 1.5 }}>
        <Alert
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setNotificationAvailable(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {response}
        </Alert>
      </Collapse>
    </React.Fragment>
  );
};
