/**
 * Attaches a given access token to a Microsoft Graph API call. Returns information about the user
 */
export async function queryMiddleware(uri: string, body: any, method: string, accessToken: any) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append('Access-Control-Allow-Methods', '*');
    headers.append('Content-Type', 'application/json');


    const options = {
        method: method,
        headers: headers,
        body: JSON.stringify(body)
    };

    return fetch(uri, options)
        .then(response => response.json())
        .catch(error => ({success: false, response: error}));
}
