import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function ResultSelection(props: any) {
  useEffect(() => {
    props.setCanGoOn(true);
  }, []);

  return (
    <React.Fragment>
    <Box display="flex" alignItems="center" borderRadius={0}>
	    <Box flexGrow={1} ml={2} fontSize={"0.875rem"}>
        <Typography mb={2}>Eintrag erfolgreich!</Typography>
        <Button variant="contained" color="primary" href="https://planning.autarq.net" target="_blank">Angebot im Planungstool generieren</Button>
	    </Box>
    </Box>
    </React.Fragment>
  );
}
