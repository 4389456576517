import React, { useState, forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import PersonalDataSection from './DataInputComponents/PersonalDataSection';
import ProjectDetailsSection from './DataInputComponents/ProjectDetailsSection';
import ProjectAddressSection from './DataInputComponents/ProjectAddressSection';
import GeneralDataSection from './DataInputComponents/GeneralDataSection';
import AddPersonPane from './DataInputComponents/AddPersonPane';

const DataInput = forwardRef(( props: any, _ref:any ) => {
  const personStateRef = useRef();
  const projectAddressStateRef = useRef();
  const generalInformationStateRef = useRef();
  const projectStateRef = useRef();
  const functionPersonStateRef = useRef();

  useImperativeHandle(_ref, () => ({
    getChildCount: () => {
      const person = getPersonState();
      const projectAddress = getProjectAddressState();
      if(projectAddress.deviates === false) {
        projectAddress.street = person.street;
        projectAddress.streetNumber = person.streetNumber;
        projectAddress.zip = person.zip;
        projectAddress.city = person.city;
        projectAddress.country = person.country;
      }

      return { 
        id: getDealId(),
        person,
        general: getGeneralState(),
        projectAddress,
        projectDetails: getProjectDetailsState(),
        functionPersons: getFunctionPersonsState(),
      };
    },
  }));

  const getPersonState = () => {
    const ref = personStateRef.current as any;
    if(ref === undefined) {
      return '';
    }
    const childState = ref.getChildCount();
    return childState;
  };

  const getGeneralState = () => {
    const ref = generalInformationStateRef.current as any;
    if(ref === undefined) {
      return '';
    }
    const childState = ref.getChildCount();
    return childState;
  };

  const getProjectAddressState = () => {
    const ref = projectAddressStateRef.current as any;
    if(ref === undefined) {
      return '';
    }
    const childState = ref.getChildCount();
    return childState;
  };

  const getProjectDetailsState = () => {
    const ref = projectStateRef.current as any;
    if(ref === undefined) {
      return '';
    }
    const childState = ref.getChildCount();
    return childState;
  };

  const getFunctionPersonsState = () => {
    const ref = functionPersonStateRef.current as any;
    if(ref === undefined) {
      return '';
    }
    const childState = ref.getChildData();
    console.log(childState);
    return childState;
  };

  const getDealId = () => {
    if(props.applicationState.customer.kind === 'existing') {
      return props.applicationState.selectedDeal.id;
    }
    return null;
  }

  const handleChange = (fieldType: string, fieldValue: string) => {
    console.log('Data was changed with fieldType: ' + fieldType + ' and value: ' + fieldValue);
  };

  return (
    <React.Fragment>
      <PersonalDataSection ref={personStateRef} applicationState={props.applicationState} setRequiredFieldsProvided={props.setRequiredFieldsProvided}/>
      <ProjectAddressSection ref={projectAddressStateRef} applicationState={props.applicationState}/>
      <GeneralDataSection ref={generalInformationStateRef} applicationState={props.applicationState}/>
      <ProjectDetailsSection ref={projectStateRef} applicationState={props.applicationState}/>
      <AddPersonPane ref={functionPersonStateRef} applicationState={props.applicationState}></AddPersonPane>
    </React.Fragment>
  );
});

export default React.memo(DataInput);