import React, { useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import autarqLogoGreyBG from '../assets/autarqLogoGreyBG.png';
import StepButtons from './WebformSurveyComponents/StepButtons';

import ModeSelection from './ModeSelection';
import DealSelection from './DealSelectioon';
import DataInput from './DataInput';
import ResultInput from './ResultSelection';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.autarq.com/">
        autarq GmbH
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const steps = ['Modusauswahl', 'Dealauswahl', 'Datenerfassung', 'Ergebnis'];

function getStepContent(
    step: number,
    applicationState: any,
    setApplicationState: any,  
    canGoOn: any,
    setCanGoOn: any,
    dataInputRef: any,
    setRequiredFieldsProvided: any,
  ) {
  switch (step) {
    case 0:
      return <ModeSelection setApplicationState = {setApplicationState} applicationState = {applicationState} canGoOn={canGoOn} setCanGoOn={setCanGoOn}/>;
    case 1:
      return <DealSelection setApplicationState = {setApplicationState} applicationState = {applicationState} canGoOn={canGoOn} setCanGoOn={setCanGoOn} setRequiredFieldsProvided={setRequiredFieldsProvided}/>;
    case 2:
      return <DataInput setApplicationState = {setApplicationState} applicationState = {applicationState} canGoOn={canGoOn} setCanGoOn={setCanGoOn} ref={dataInputRef} setRequiredFieldsProvided={setRequiredFieldsProvided}/>;
    case 3:
      return <ResultInput setApplicationState = {setApplicationState} applicationState = {applicationState} canGoOn={canGoOn} setCanGoOn={setCanGoOn} />;
    default:
      throw new Error('Unknown step');
  }
}

export default function WebformSurvey() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [canGoOn, setCanGoOn] = React.useState(false);
  const [requiredFieldsProvided, setRequiredFieldsProvided] = React.useState(false);

  const [applicationState, setApplicationState] = React.useState( { customer: { kind: 'new' } } );

  const dataInputRef = useRef();

  const handleNext = () => {
    if(applicationState.customer.kind === 'new' && activeStep === 0) {
      console.log("jump to the last step");
      setActiveStep(activeStep + 2);
    } else {
      setActiveStep(activeStep + 1);
    }
    if(activeStep === 2) {
      const ref = dataInputRef.current as any;
      if(ref === undefined) {
        return '';
      }
      const childState = ref.getChildCount();
      console.log(childState);
      return childState;
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: 'relative',
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <img src={autarqLogoGreyBG} alt='Autarq Logo' height={478/2121*120} width={120}></img>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component="h1" variant="h4" align="center">
            Digitaler Erfassungsbogen
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant="h5" gutterBottom>
                  Eintragen
                </Typography>
                <Typography variant="subtitle1">
                  Element eingetragen
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep, applicationState, setApplicationState, canGoOn, setCanGoOn, dataInputRef, setRequiredFieldsProvided)}
                <StepButtons applicationState={applicationState} activeStep={activeStep} setActiveStep={setActiveStep} dataInputRef={dataInputRef} steps={steps} requiredFieldsProvided={requiredFieldsProvided}></StepButtons>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Copyright />
      </Container>
    </React.Fragment>
  );
}
