import React, { useState, forwardRef, useEffect, useImperativeHandle } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { FormControl, SelectChangeEvent } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FileUpload from "react-mui-fileuploader"

import AddRoofPane from './AddRoofPane';

const ProjectDetailsSection = forwardRef(( props: any, _ref:any ) => {

  const [roofCondition, setRoofCondition] = React.useState('');
  const [houseType, setHouseType] = React.useState('');
  const [numberOfStories, setNumberOfStories] = React.useState('');
  const [roofs, setRoofs] = React.useState([]);
  const [allocation, setAllocation] = React.useState('');
  const [maximumTileCount, setMaximumTileCount] = React.useState('');
  const [tile, setTile] = React.useState('');
  const [loftHasSpace, setLoftHasSpace] = React.useState(false);
  const [loftIsDeveloped, setLoftIsDeveloped] = React.useState(false);
  const [plantRoomHasSpace, setPlantRoomHasSpace] = React.useState(false);
  const [drawingExisting, setDrawingExisting] = React.useState(false);
  const [measurementExisting, setMeasurementExisting] = React.useState(false);
  const [roofEntryPointMarked, setRoofEntryPointMarked] = React.useState(false);
  const [locationPVWMarked, setLocationPVWMarked] = React.useState(false);
  const [filesToUpload, setFilesToUpload] = useState<any>([]);

  useEffect(() => {
console.log ('ONUSEEFFEKT');
console.log (props);	
    if(props.applicationState.customer.kind === 'existing') {
      // TODO: Abfangen, dass Felder undefined sein können
      if(props.applicationState.selectedDeal.project.roofCondition) {
        setRoofCondition(props.applicationState.selectedDeal.project.roofCondition);
      }
      if(props.applicationState.selectedDeal.project.houseType) {
        setHouseType(props.applicationState.selectedDeal.project.houseType);
      }
      if(props.applicationState.selectedDeal.project.numberOfStories) {
        setNumberOfStories(props.applicationState.selectedDeal.project.numberOfStories);
      }
      if(props.applicationState.selectedDeal.project.allocation) {
        setAllocation(props.applicationState.selectedDeal.project.allocation);
        if(props.applicationState.selectedDeal.project.allocation === 'semi') {
          setMaximumTileCount(props.applicationState.selectedDeal.project.maximumTileCount);
        }
      }
      if(props.applicationState.selectedDeal.project.tile) {
        setTile(props.applicationState.selectedDeal.project.tile);
      }
      if(props.applicationState.selectedDeal.project.loftHasSpace) {
        setLoftHasSpace(props.applicationState.selectedDeal.project.loftHasSpace);
      }
      if (props.applicationState.selectedDeal.project.loftIsDeveloped) {
        setLoftIsDeveloped(props.applicationState.selectedDeal.project.loftIsDeveloped);
      }
      if (props.applicationState.selectedDeal.project.plantRoomHasSpace) {
        setPlantRoomHasSpace(props.applicationState.selectedDeal.project.plantRoomHasSpace);
      }
      if (props.applicationState.selectedDeal.project.roofs) {
        setRoofs(props.applicationState.selectedDeal.project.roofs);
      }
      if (props.applicationState.selectedDeal.project.drawingExisting) {
        setDrawingExisting(props.applicationState.selectedDeal.project.drawingExisting);
      }
      if (props.applicationState.selectedDeal.project.measurementExisting) {
        setMeasurementExisting(props.applicationState.selectedDeal.project.measurementExisting);
      }
      if (props.applicationState.selectedDeal.project.roofEntryPointMarked) {
        setRoofEntryPointMarked(props.applicationState.selectedDeal.project.roofEntryPointMarked);
      }
      if (props.applicationState.selectedDeal.project.locationPVWMarked) {
        setLocationPVWMarked(props.applicationState.selectedDeal.project.locationPVWMarked);
      }
    }
  }, []);

  useImperativeHandle(_ref, () => ({
    getChildCount: () => {
      let plans = new FormData()
      filesToUpload.forEach((file:any) => plans.append("files", file))
      return { 
        roofCondition,
        houseType,
        numberOfStories,
        roofs,
        allocation,
        maximumTileCount,
        tile,
        loftHasSpace,
        loftIsDeveloped,
        plantRoomHasSpace,
        drawingExisting,
        measurementExisting,
        roofEntryPointMarked,
        locationPVWMarked,
        plans: JSON.stringify(Object.fromEntries(plans))
      };
    },
  }));

  const handleFilesChange = (files:any[]) => {
    // Update chosen files
    setFilesToUpload([ ...files ])
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom sx={{ my: 4 }}>
        Daten zum Bauvorhaben
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="roof-condition-label">Zustand des Daches</InputLabel>
            <Select
              labelId="roof-condition-label"
              id="roof-condition-select"
              value={roofCondition}
              label="Zustand des Daches"
              onChange={e => setRoofCondition(e.target.value)}
            >
              <MenuItem value={'in-planning'}>Neubau</MenuItem>
              <MenuItem value={'to-renovate'}>Sanierung</MenuItem>
              <MenuItem value={'isListed'}>Denkmalschutz</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="house-type-label">Gebäudetyp</InputLabel>
            <Select
              labelId="house-type-label"
              id="house-type-select"
              value={houseType}
              label="Gebäudetyp"
              onChange={e => setHouseType(e.target.value)}
            >
              <MenuItem value={'detached'}>Einfamilienhaus</MenuItem>
              <MenuItem value={'semi-detached'}>Doppelhaus</MenuItem>
              <MenuItem value={'multi-family'}>Mehrfamilienhaus</MenuItem>
              <MenuItem value={'townhouse'}>Reihenhaus</MenuItem>
              <MenuItem value={'non-residential'}>Keine Wohnimmobilie</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <InputLabel id="number-of-stories-label">Stockwerke</InputLabel>
            <Select
              labelId="number-of-stories-label"
              id="number-of-stories-select"
              value={numberOfStories}
              label="Stockwerke"
              onChange={e => setNumberOfStories(e.target.value)}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth>
            <InputLabel id="allocation-label">Belegung</InputLabel>
            <Select
              labelId="allocation-label"
              id="allocation-select"
              value={allocation}
              label="Belegung"
              onChange={e => setAllocation(e.target.value)}
            >
              <MenuItem value={'full'}>Vollbelegung der Dachflächen</MenuItem>
              <MenuItem value={'semi'}>Ertrag optimierung mit der maximalen Ziegelanzahl</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={10}>
          <TextField
            disabled={(allocation === 'full') ? true : false}
            id="maximumTileCount"
            name="maximumTileCount"
            label="max. Ziegelanzahl"
            value={maximumTileCount}
            onChange={e => setMaximumTileCount(e.target.value)}
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="tile-selection-label">Ziegelauswahl</InputLabel>
{/**/}			
            <Select
              labelId="tile-selection-label"
              id="tile-selection-select"
              value={tile}
              label="Ziegelauswahl"
              onChange={e => setTile(e.target.value)}
            >
              {
                props.applicationState.solarTiles.map((brick: any) => (
                <MenuItem value={brick.id} key={brick.id}>
                  {brick.name}
                </MenuItem>
                ))
              }

            </Select>

          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <AddRoofPane roofs = {roofs} setRoofs = {setRoofs}></AddRoofPane>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ my: 4 }}>
            Dachboden/Obergeschoss
          </Typography>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={loftIsDeveloped} onChange={e => setLoftIsDeveloped(e.target.checked)}/>} label="Dachboden ist ausgebaut" />
            <FormControlLabel control={<Checkbox checked={loftHasSpace} onChange={e => setLoftHasSpace(e.target.checked)}/>} label="Platz für PV-Wandler vorhanden" />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ my: 4 }}>
            Technikraum/Garage
          </Typography>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={plantRoomHasSpace} onChange={e => setPlantRoomHasSpace(e.target.checked)}/>} label="Platz für Batterie/Wechselrichter vorhanden" />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ my: 4 }}>
            Zeichnungen/Pläne
          </Typography>
        </Grid>
        <Grid container spacing={3} sx={{ml: 0}}>
          <Grid item xs={12} sm={6}>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={drawingExisting} onChange={e => setDrawingExisting(e.target.checked)}/>} label="Aufmaß vorhanden" />
              <FormControlLabel control={<Checkbox checked={measurementExisting} onChange={e => setMeasurementExisting(e.target.checked)}/>} label="Bemaßte Zeichnung vorhanden" />
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                <FormControlLabel control={<Checkbox disabled={!measurementExisting} checked={roofEntryPointMarked} onChange={e => setRoofEntryPointMarked(e.target.checked)}/>} label="Dacheintrittspunkt eingezeichnet" />
                <FormControlLabel control={<Checkbox disabled={!measurementExisting} checked={locationPVWMarked} onChange={e => setLocationPVWMarked(e.target.checked)}/>} label="Standort Wandler eingezeichnet" />
              </Box>
            </FormGroup>
          </Grid>
          {/*
          <Grid item xs={12} sm={6}>
            <FileUpload
            title=""
            header="Pläne hier hereinziehen"
            buttonLabel="Dateien auswählen"
            buttonRemoveLabel="Alle löschen"
            maxUploadFiles={5}
            maxFileSize={10}
            allowedExtensions={['jpg', 'jpeg', 'pdf', 'tiff', 'png']}
            leftLabel=""
            rightLabel=""
            showPlaceholderImage={false}
            BannerProps={{ elevation: 0, variant: "outlined" }}
            ContainerProps={{
              elevation: 0,
              variant: "elevation",
              sx: { p: 1 }
            }}
            multiFile={true}
            onFilesChange={handleFilesChange}
            onContextReady={(context) => {}}
            />
          </Grid>
          */}
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default React.memo(ProjectDetailsSection);
