import * as React from 'react';
import NewRoofCard from './NewRoofCard';
import RoofsDataGrid from './RoofsDataGrid';

const AddRoofPane = (props: any) => {

  return (
    <React.Fragment>
      <NewRoofCard roofs = {props.roofs} setRoofs = {props.setRoofs}/>
      <RoofsDataGrid roofs = {props.roofs} setRoofs = {props.setRoofs}/>
    </React.Fragment>
  )
}

export default AddRoofPane