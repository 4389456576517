import React, { useState, forwardRef, useEffect, useImperativeHandle } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import { FormControl, SelectChangeEvent } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    overflow: "hidden",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const AddPersonModal = forwardRef(( props: {
	applicationState: any;
	handleModalClose: () => void;
}, _ref:any ) => {
    const [addButtonDisabled, setAddButtonDisabled] = useState(true);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        clearFields();
        props.handleModalClose();
    };
	const contactType = props.applicationState.contactTypes;

    const [company, setCompany] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [street, setStreet] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [email, setEmail] = useState('');
	const [contactTypes, setcontactTypes] = useState('');

    useEffect(() => {
		console.log (props.applicationState.contactTypes);
        if(contactTypes !== '' && firstName !== '' && lastName !== '' && street !== '' && streetNumber !== '' && zip !== '' && city !== '' && email !== '') {
            setAddButtonDisabled(false);
        } else {
            setAddButtonDisabled(true);
        }
      });

    useImperativeHandle(_ref, () => ({
        getChildData: () => {
          return {
            company,
            firstName,
            lastName,
            street,
            streetNumber,
            zip,
            city,
			contactTypes,
            country,
            email,
          };
        },
    }));

    const clearFields = () => {
        setCompany('');
        setFirstName('');
        setLastName('');
        setStreet('');
        setStreetNumber('');
        setZip('');
        setCity('');
        setCountry('');
        setEmail('');
		setcontactTypes('');
        setAddButtonDisabled(true);
    };

    return (
        <React.Fragment>
            <Button sx={{mt: 4}} variant='contained' onClick={handleOpen}>Dachdecker:in/Elektriker:in hinzufügen</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography sx={{ mb: 3 }} variant="h6" gutterBottom>Dachdecker:in/Elektriker:in hinzufügen</Typography>
                    <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="type-of-person-selection-label">Funktion der Person</InputLabel>
                            <Select
                                labelId="type-of-person-selection-label"
                                id="type-of-person-selection-select"
                                value={contactTypes}
                                label="Ziegelauswahl"
                                onChange={e => setcontactTypes(e.target.value)}
                            >
								{contactType.map((contactType: any) => (
									<MenuItem key={contactType.id} value={contactType.label}>
									{contactType.label}
									</MenuItem>
								))}						
                            </Select>
                        </FormControl>
                    </Grid>    
                    <Grid item xs={12}>
                            <TextField
                                id="company"
                                name="company"
                                label="Firmenname"
                                value={company}
                                onChange={e => setCompany(e.target.value)}
                                fullWidth
                                autoComplete="company"
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="firstName"
                                name="firstName"
                                label="Vorname"
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                                fullWidth
                                autoComplete="given-name"
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="lastName"
                                name="lastName"
                                label="Nachname"
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                                fullWidth
                                autoComplete="family-name"
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={9}>
                            <TextField
                                required
                                id="street"
                                name="street"
                                label="Straße"
                                value={street}
                                onChange={e => setStreet(e.target.value)}
                                fullWidth
                                autoComplete="street"
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                required
                                id="streetNumber"
                                name="streetNumber"
                                label="Hausnummer"
                                value={streetNumber}
                                onChange={e => setStreetNumber(e.target.value)}
                                fullWidth
                                autoComplete="street-number"
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                required
                                id="zip"
                                name="zip"
                                label="Postleitzahl"
                                value={zip}
                                onChange={e => setZip(e.target.value)}
                                fullWidth
                                autoComplete="zip"
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                required
                                id="city"
                                name="city"
                                label="Stadt"
                                value={city}
                                onChange={e => setCity(e.target.value)}
                                fullWidth
                                autoComplete="city"
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="county"
                                name="country"
                                label="Land"
                                value={country}
                                onChange={e => setCountry(e.target.value)}
                                fullWidth
                                autoComplete="country"
                                variant="standard"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="mail"
                                name="mail"
                                label="E-Mail"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                fullWidth
                                autoComplete="email"
                                variant="standard"
                            />
                        </Grid>
                    </Grid>
                    <Button sx={{mt: 4}} disabled={addButtonDisabled} variant='contained' onClick={handleClose}>Eintragen</Button>
                </Box>
            </Modal>
      </React.Fragment>
    )
});
  
export default React.memo(AddPersonModal);