import React, { useState, forwardRef, useEffect, useImperativeHandle } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormControl, SelectChangeEvent } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Select from '@mui/material/Select';
import { deDE } from '@mui/x-date-pickers/locales';
import { InputAdornment } from '@mui/material';

dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.utc()

dayjs.tz.setDefault('Europe/London');

const GeneralDataSection = forwardRef(( props: any, _ref:any ) => {
  const [desiredInstallationDate, setDesiredInstallationDate] = React.useState<Dayjs | null>(dayjs().add(5, 'month'));
  const [consumptionPeople, setConsumptionPeople] = React.useState('');
  const [annualPowerDemand, setAnnualPowerDemand] = React.useState('');
  const [estimatedBudget, setEstimatedBudget] = React.useState('');

  useEffect(() => {
    if(props.applicationState.customer.kind === 'existing') {
      if(props.applicationState.selectedDeal.general.consumptionPeople) {
        setConsumptionPeople(props.applicationState.selectedDeal.general.consumptionPeople);
      }
      if (props.applicationState.selectedDeal.general.desiredInstallationDate) {
        setDesiredInstallationDate(dayjs(props.applicationState.selectedDeal.general.desiredInstallationDate));
      }
      if (props.applicationState.selectedDeal.general.annualPowerDemand) {
        setAnnualPowerDemand(props.applicationState.selectedDeal.general.annualPowerDemand);
      }
      if (props.applicationState.selectedDeal.general.estimatedBudget) {
        setEstimatedBudget(props.applicationState.selectedDeal.general.estimatedBudget);
      }
    }
  }, []);

  useImperativeHandle(_ref, () => ({
    getChildCount: () => {
      let date = null;
      if(desiredInstallationDate !== null) {
        // workaround because of timezone issues (timezone is not transferred to server)
        date = desiredInstallationDate.add(1, 'hour');
      }
      console.log(date);
      return { 
        desiredInstallationDate: date,
        consumptionPeople,
        annualPowerDemand,
        estimatedBudget,
      };
    },
  }));

  const handleChange = (date: any) => {
    setDesiredInstallationDate(dayjs(date));
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom sx={{ my: 4 }}>
        Allgemeine Daten
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} locale={deDE}>
            <DatePicker
              label="Gewünschtes Installationsdatum"
              value={desiredInstallationDate}
              onChange={(newValue) => handleChange(newValue)}
		format="DD.MM.YYYY"
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="consumption-people-label">Haushaltsmitglieder</InputLabel>
            <Select
              labelId="consumption-people-label"
              id="consumption-people-select"
              value={consumptionPeople}
              label="consumptionPeople"
              onChange={e => setConsumptionPeople(e.target.value)}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="annualDemand"
            name="annualDemand"
            label="Jährlicher Bedarf"
            value={annualPowerDemand} 
            onChange={e => setAnnualPowerDemand(e.target.value)}
            fullWidth
            variant="standard"
	    InputProps={{
                endAdornment: (
	           <InputAdornment position="end" style={{ color: '#666' }}>
			in kWh
	           </InputAdornment>
                ),
	    }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="budget"
            name="budget"
            label="Budgetvorstellung"
            value={estimatedBudget}
            onChange={e => setEstimatedBudget(e.target.value)}
            fullWidth
            variant="standard"
	    InputProps={{
                endAdornment: (
	           <InputAdornment position="end" style={{ color: '#666' }}>
			in EUR
	           </InputAdornment>
                ),
	    }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
});

export default React.memo(GeneralDataSection);
