import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import DealDataGrid from './DealSelectionComponents/DealDataGrid';

export default function DealSelection(props: any) {
  useEffect(() => {

  });

  useEffect(() => {
    props.setRequiredFieldsProvided(false);
  }, []);

  const handleSelection = (deal: any) => {
    const applicationState = props.applicationState;
    props.applicationState.selectedDeal = deal;
    props.setRequiredFieldsProvided(true);
    props.setApplicationState(applicationState);
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Kunde auswählen
      </Typography>
      <DealDataGrid handleSelection = {handleSelection}/>
    </React.Fragment>
  );
}