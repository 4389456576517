import React, { useState, forwardRef, useEffect, useImperativeHandle } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

const PersonalDataSection = forwardRef(( props: any, _ref:any ) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [street, setStreet] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [zip, setZip] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');

  useEffect(() => {
    // check if all required field data is provided
    if(firstName !== '' && lastName !== '' && street !== '' && streetNumber !== '' && zip !== '' && city !== '' && country !== '') {
      props.setRequiredFieldsProvided(true);
    } else {
      props.setRequiredFieldsProvided(false);
    }
  });

  useEffect(() => {
    if(props.applicationState.customer.kind === 'existing') {
      // TODO: Abfangen, dass Felder undefined sein können
      if(props.applicationState.selectedDeal.person.firstName) {
        setFirstName(props.applicationState.selectedDeal.person.firstName);
      }
      if(props.applicationState.selectedDeal.person.lastName) {
        setLastName(props.applicationState.selectedDeal.person.lastName);
      }
      if(props.applicationState.selectedDeal.person.street) {
        setStreet(props.applicationState.selectedDeal.person.street);
      }
      if(props.applicationState.selectedDeal.person.streetNumber) {
        setStreetNumber(props.applicationState.selectedDeal.person.streetNumber);
      }
      if(props.applicationState.selectedDeal.person.zip) {
        setZip(props.applicationState.selectedDeal.person.zip);
      }
      if(props.applicationState.selectedDeal.person.city) {
        setCity(props.applicationState.selectedDeal.person.city);
      }
      if(props.applicationState.selectedDeal.person.country) {
        setCountry(props.applicationState.selectedDeal.person.country);
      }
    }
  }, []);

  useImperativeHandle(_ref, () => ({
    getChildCount: () => {
      return { 
        firstName,
        lastName,
        street,
        streetNumber,
        zip,
        city,
        country
      };
    },
  }));

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Persönliche Daten (Bauherr:in)
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="Vorname"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Nachname"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            fullWidth
            autoComplete="family-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Straße"
            value={street}
            onChange={e => setStreet(e.target.value)}
            fullWidth
            autoComplete="street"
            variant="standard"
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            required
            id="streetNumber"
            name="streetNumber"
            label="Hausnummer"
            value={streetNumber}
            onChange={e => setStreetNumber(e.target.value)}
            fullWidth
            autoComplete="streetNumber"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="Stadt"
            value={city}
            onChange={e => setCity(e.target.value)}
            fullWidth
            autoComplete="shipping address-level2"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Postleitzahl"
            value={zip}
            onChange={e => setZip(e.target.value)}
            fullWidth
            autoComplete="shipping postal-code"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label="Land"
            value={country}
            onChange={e => setCountry(e.target.value)}
            fullWidth
            autoComplete="shipping country"
            variant="standard"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default React.memo(PersonalDataSection);
