import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useMsal } from "@azure/msal-react";
import { queryMiddleware } from "../helper/queryMiddleware";
import { loginRequest } from "../authConfig";

let middlewareUrl = 'http://localhost:3001';
if (process.env.NODE_ENV === 'production') {
  // dev / staging is on 3000
  middlewareUrl = 'https://webform-api.autarq.net';
} else if (process.env.NODE_ENV === 'test') {
  middlewareUrl = 'https://webform-api.autarq.net';
}

export default function ModeSelection(props: any) {
  const {instance, accounts} = useMsal();

  useEffect(() => {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };

    const url   = `${middlewareUrl}/api/v1/get-products`;

    instance.acquireTokenSilent(request).then((response) => {
      queryMiddleware(url, {}, "POST", response.accessToken)
      .then((data) => processGetProductsAnswer(data))
    }).catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        queryMiddleware(url, {}, "POST", response.accessToken)
          .then((data) => processGetProductsAnswer(data))
      });
    });

	const url2 = `${middlewareUrl}/api/v1/get-pipedriveFields`;
	instance.acquireTokenSilent(request).then((response) => {
		queryMiddleware(url2, {}, "POST", response.accessToken)
		.then((data) => processGetPipedriveAnswer(data))
	}).catch((e) => {
		console.log (e);
	});


    props.setCanGoOn(true);
  }, []);

  const processGetPipedriveAnswer = (data:any) => {
	if (data.success !== true) return;
	if (!Array.isArray(data.data)) return;

	props.applicationState.contactTypes = data.data;

	props.setApplicationState({ ...props.applicationState, contactTypes: {ok : 1}});
	props.setCanGoOn(true);
  }

  const processGetProductsAnswer = (data:any) => {
    if(data.success !== true) return;
    if(!Array.isArray(data.data)) return;	
    props.setApplicationState({ ...props.applicationState, solarTiles: data.data });
    props.setCanGoOn(true);
  };

  const handleCustomerKindChange = (e: any):void => {
    if (e.target.value === 'new') {
      props.applicationState.customer.kind = 'new';
      props.setApplicationState( { ...props.applicationState, customer: { kind: 'new'} } );
    }
    else if (e.target.value === 'existing') {
      props.setApplicationState( { ...props.applicationState, customer: { kind: 'existing'} } );
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="mode-of-customer">Kundenart</InputLabel>
            <Select
              labelId="mode-of-customer-label"
              id="mode-of-customer"
              value={props.applicationState.customer.kind}
              label="Kundenart"
              onChange={handleCustomerKindChange}
            >
              <MenuItem value={'new'}>Neukunde</MenuItem>
              <MenuItem value={'existing'}>Bestandskunde</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}