let redirectUri = 'http://localhost:3000';
if (process.env.NODE_ENV === 'production') {
  redirectUri = 'https://webform.autarq.net';
} else if (process.env.NODE_ENV === 'staging') {
  redirectUri = 'https://webform.autarq.net';
}

export const msalConfig = {
    auth: {
      clientId: "dc08861f-5a6f-47e7-a7d7-9ec355bc2ae7",
      authority: "https://login.microsoftonline.com/af112989-8a95-47d5-b644-9a9fc9e52498",
      redirectUri: redirectUri,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["api://dc08861f-5a6f-47e7-a7d7-9ec355bc2ae7/Webform"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  /*
  export const graphConfig = {
      graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
  };
  */